import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { Waypoint } from "react-waypoint";
import pic01 from "../assets/images/pic01.jpg";
import Header from "../components/Header";
import Layout from "../components/layout";
import Nav from "../components/Nav";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    };
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  };

  render() {
    return (
      <Layout>
        <Helmet title="Gatsby Starter - Stellar" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Welcome to Mastered Software</h2>
                </header>
                <h3>Quality Software Development and Consulting</h3>
                <p>
                  Mastered Software has mastered the art and science of quality
                  Software Development. We specialize in developing scalable,
                  high-performance applications and games using open source
                  technologies and frameworks. Contact us for all your custom
                  software solutions.
                </p>
                <ul className="actions">
                  <li>
                    <Link to="/about-us" className="button">
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Games</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-rocket"></span>
                <h3>Astriarch</h3>
                <p>
                  Astriarch is a Turn-based, Multi-Player Space Strategy Game.
                </p>
              </li>
              <li>
                <span className="icon major style3 fa-flask"></span>
                <h3>Chem Fight</h3>
                <p>
                  Chem Fight is a Chemistry Battle Game. It was developed for
                  the js13kGames competition.
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-diamond"></span>
                <h3>Qlinqo</h3>
                <p>
                  Drop the chips at the top of the playfield to try to land them
                  in the highest scoring slots.
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/games" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>News</h2>
              <p>Find out the latest developments at Mastered Software</p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/news" className="button special">
                    Latest News
                  </Link>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Index;
